<template>
  <div v-bind="fieldAttributes.containerAttributes">
    <div v-if="showTitle" class="text-subtitle-1 mb-n3 ml-2">{{ fieldAttributes.title }}</div>
    <radio
      :fieldAttributes="fieldAttributes"
      :value="value"
      :fieldAttrInput="fieldAttrInput"
      :checkReadOnly="checkReadOnly"
      :field="field"
      :result="result"
      v-on="$listeners"
    >
    </radio>
  </div>
</template>
<script>
import radio from "@/commonComponents/radio.vue";
export default {
  components: { radio },
  props: ["result","fieldAttributes", "fieldAttrInput", "checkReadOnly", "field","value"],
  computed: {
    showTitle() {
      let showTitle = true;
      if (typeof this.fieldAttributes.fieldAttrInput!=='undefined' && typeof  this.fieldAttributes.fieldAttrInput.showTitle!=='undefined' &&  this.fieldAttributes.fieldAttrInput.showTitle ===false) {
        showTitle = false;
      }
      return showTitle;
    },
  }
};
</script>